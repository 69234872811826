import en from './en/no_filename.json';
import de from './de/no_filename.json';
import es from './es/no_filename.json';
import fr from './fr/no_filename.json';
import it from './it/no_filename.json';
import ja from './ja/no_filename.json';
import ko from './ko/no_filename.json';
import pt from './pt/no_filename.json';
import zh_cn from './zh_cn/no_filename.json';

export const resources = {
  en: { lang: en },
  de: { lang: de },
  es: { lang: es },
  fr: { lang: fr },
  it: { lang: it },
  ja: { lang: ja },
  ko: { lang: ko },
  pt: { lang: pt },
  zh_cn: { lang: zh_cn }
} as const;
