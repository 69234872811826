import { Axios } from '.';

import axios_default from 'axios';
import { POST_CASE_REFRESH_TOKEN_ISSUANCE_API } from '../Constant/Api/apiEndpoints';
import { BACKEND_BASE_URL, BACKEND_BASE_URL_ACCOUNT } from '../../config';

const unAuthorizedInstance = axios_default.create({
  baseURL: BACKEND_BASE_URL_ACCOUNT,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export const requestAccessToken = async () => {
  try {
    const { data: responseData } = await unAuthorizedInstance.patch(
      POST_CASE_REFRESH_TOKEN_ISSUANCE_API
    );

    const { accessToken } = responseData.data;
    if (typeof accessToken !== 'string') {
      console.error('accessToken is not string');
      return null;
    }
    return { accessToken };
  } catch (err) {
    console.error(err, 'refresh token expired');
  }
};

export const axios = Axios.createAxiosInstance({
  baseURL: BACKEND_BASE_URL,
  requestAccessToken: requestAccessToken
});
