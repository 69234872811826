import { useQuery } from '@tanstack/react-query';
import { axios } from '../../Auth/Axios/axiosInstance';
import { USER_SETTING_API_URL } from '../../Auth/Constant/Api/apiEndpoints';
import i18next from 'i18next';
import {
  TExportSetting,
  TNetworkSetting,
  TSetting,
  TSystemSetting
} from '../../Types/setting';

const getSettingQueryByPath =
  <T>(path: string) =>
  () => {
    return useQuery<T>({
      queryKey: ['setting', path],
      queryFn: () =>
        axios.get(`${USER_SETTING_API_URL}/${path}`).then((res) => res.data),
      suspense: true
    });
  };

export const useSystemSettingQuery = getSettingQueryByPath<{
  data: TSystemSetting;
}>('system');
export const useExportSettingQuery = getSettingQueryByPath<{
  data: TExportSetting;
}>('export');
export const useNetworkSettingQuery = getSettingQueryByPath<{
  data: TNetworkSetting;
}>('network');

export const useSettingQuery = () => {
  return useQuery<{ data: TSetting }>({
    queryKey: ['setting'],
    queryFn: () => {
      return axios.get(USER_SETTING_API_URL).then((res) => {
        if (res.data.success && res.data.data) {
          i18next.changeLanguage(
            res.data.data.systemSetting?.language.toLowerCase() ?? 'en'
          );
        }
        return res.data;
      });
    },
    refetchOnWindowFocus: false,
    retry: false
  });
};
