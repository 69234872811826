import {
  Cicon,
  Dialogue,
  DialogueTitle,
  DialogueContent,
  Typography
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { onCloseApp } from '../../Utils/postMessage';
import i18next from 'i18next';
export const UnknownErrorDialog = () => {
  return (
    <Dialogue
      open={true}
      PaperProps={{
        sx: {
          width: '960px !important',
          maxWidth: '960px',
          height: '640px',
          maxHeight: '640px !important',
          margin: '0px'
        }
      }}
    >
      <DialogueTitle
        isClose
        onClose={onCloseApp}
        sx={{
          height: '68px',
          padding: '22px 20px 14px 0px'
        }}
      />
      <DialogueContent
        sx={{
          padding: '0px'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            paddingTop: '209px',
            justifyContent: 'flex-start'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <Cicon
              icon="Warning"
              svgProps={{ width: '36px', height: '36px' }}
            />
            <Typography variant="H24">
              {i18next.t('unknown_error.title')}
            </Typography>
          </Stack>
        </Stack>
      </DialogueContent>
    </Dialogue>
  );
};
