import { Suspense, lazy } from 'react';

const AppLazy = lazy(() => import('./App'));

export const AppSuspense = () => {
  return (
    <Suspense fallback={<div />}>
      <AppLazy />
    </Suspense>
  );
};
