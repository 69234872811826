import { FormProvider as FormProvider_, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useSystemSettingQuery } from '../../Hooks/ReactQuery/useSettingQuery';
import i18n from 'i18next';

export const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading: isUserSettingLoading, data: userSettingData } =
    useSystemSettingQuery();

  const methods = useForm();
  const { reset } = methods;

  useEffect(() => {
    if (!isUserSettingLoading) {
      reset({
        ...userSettingData?.data
      });
      i18n.changeLanguage(userSettingData?.data.language.toLowerCase() ?? 'en');
    }
  }, [isUserSettingLoading, reset, userSettingData?.data]);

  return <FormProvider_ {...methods}>{children}</FormProvider_>;
};
