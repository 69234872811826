import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RecoilRoot } from 'recoil';
import { IThemeProvider } from './IthemeProvider';
import RecoilNexus from 'recoil-nexus';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import packageJson from '../package.json';
import { ENVIRONMENT } from './config';
import { AppWrapper } from './Compositions/AppWrapper';
import { FormProvider } from './Compositions/Default/FormProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Routers from './Routes';
import './i18n';
import { ErrorBoundary } from 'react-error-boundary';
import { UnknownErrorDialog } from './Components/Dialog/UnknownErrorDialog';
import { AppLoading } from './Components/AppLoading';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

console.debug(
  `#SETTING_MODULE: ${ENVIRONMENT.toUpperCase()} v${packageJson.version}`
);
root.render(
  <React.StrictMode>
    <IThemeProvider>
      <ErrorBoundary FallbackComponent={UnknownErrorDialog}>
        <Suspense
          fallback={
            <>
              <AppLoading />
            </>
          }
        >
          <AppWrapper>
            <QueryClientProvider
              client={
                new QueryClient({
                  defaultOptions: {
                    queries: {
                      refetchOnWindowFocus: false,
                      retry: false
                    }
                  }
                })
              }
            >
              <RecoilRoot>
                <RecoilNexus />
                <Router>
                  <FormProvider>
                    <Routers />
                  </FormProvider>
                </Router>
              </RecoilRoot>
            </QueryClientProvider>
          </AppWrapper>
        </Suspense>
      </ErrorBoundary>
    </IThemeProvider>
  </React.StrictMode>
);
