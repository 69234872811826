import { RouteObject, useRoutes } from 'react-router-dom';
import { AppSuspense } from './AppSuspense';
import { UnknownErrorDialog } from './Components/Dialog/UnknownErrorDialog';

export const routePath: RouteObject[] = [
  {
    path: '/',
    element: <AppSuspense />,
    errorElement: <div />
  },
  {
    path: '*',
    element: <UnknownErrorDialog />,
    errorElement: <div />
  }
];

export default function Routers() {
  const routes = useRoutes(routePath);
  return routes;
}
