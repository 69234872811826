import { ALLOW_ORIGIN_LIST } from '../Constants/iframe';
import { normalizeUrl } from './normalize';

export const onModuleSnackbar = (text: string) => {
  const referrer = normalizeUrl(document.referrer);
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage(
    { message: 'onModuleSnackbar', data: { text } },
    referrer
  );
  console.log('App onModuleSnackbar request');
};
export const onCloseApp = () => {
  const referrer = normalizeUrl(document.referrer);
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage({ message: 'onClose' }, referrer);
  console.log('App onClose request');
};

export const onError = () => {
  const referrer = normalizeUrl(document.referrer);
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage({ message: 'onError' }, referrer);
  console.log('App onError request');
};

export const onSuccess = (response: any) => {
  const referrer = normalizeUrl(document.referrer);
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage({ message: 'onSuccess', data: response }, referrer);
  console.log('App onSuccess request');
};
